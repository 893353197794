<template>
  <div class="features">
    <h1 class="title is-1 mt-5 features-title">{{ $t('nav.features') }}</h1>

    <section class="mt-7 container p-2">
      <div class="columns is-vcentered">
        <div class="column">
          <div class="videowrapper">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/-uMjHJfNyeM"
              title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
              encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
          </div>
        </div>
        <div class="column m-1">
          <h3 class="title is-3 has-text-left">{{ $t('landing.features.plan') }}</h3>
          <p class="has-text-left pl-3" v-html="$t('landing.features.plan_text')"/>
        </div>
      </div>
    </section>

    <section class="mt-7 container p-2">
      <div class="columns is-vcentered">
        <div class="column m-1">
          <h3 class="title is-3 has-text-left">{{ $t('landing.features.model') }}</h3>
          <p class="has-text-left pl-3" v-html="$t('landing.features.model_text')"/>
        </div>
        <div class="column">
          <img loading="lazy" src="~@/assets/landing/powerCurve.png"/>
        </div>
      </div>
    </section>

    <section class="mt-7 container p-2">
      <div class="columns is-vcentered">
        <div class="column">
          <img loading="lazy" src="~@/assets/landing/analysis.png"/>
        </div>

        <div class="column p-1">
          <h3 class="title is-3 has-text-left">{{ $t('landing.features.analysis') }}</h3>
          <p class="has-text-left pl-3" v-html="$t('landing.features.analysis_text')"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default{
  name: 'Features',
  metaInfo(){
    return {
      link: this.$getAlternates('features'),
    };
  },
};
</script>

<style lang="scss" scoped>

.features-title {
  width: 350px;
  margin: auto;
  background: linear-gradient(to bottom, #FFF 50%, $primary-lighter 50%);
}
</style>
